<template>  
  <OModal ref="OModalRef" @hidden="" :modalOptions="{focus: false}">
    <div class="modal-dialog">
        <div class="modal-content">

            <div class="modal-header d-flex flex-column align-items-start gap-2">
              <div class="gap-2 d-flex gap-2 w-100">
                <input type="text" v-model="meetingRow.Name" class="form-control fw-bold fs-5 border-0 ps-0">
                <button type="button" class="btn-close align-self-start" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
            </div>

            <div class="modal-body d-flex flex-column gap-2">
                <div>
                  <label class="fw-bold text-muted pb-1">Start date</label>
                  <ODatePicker inputClassName="form-control form-item " v-model="meetingRow.StartTime" format="Short Date"></ODatePicker>
                </div>
                <div>
                  <label class="fw-bold text-muted pb-1">End date</label>
                  <ODatePicker inputClassName="form-control form-item" v-model="meetingRow.DateTime" format="Short Date"></ODatePicker>
                </div>
                <div>
                  <label for="meeting-description" class="fw-bold small text-muted pb-1">Meeting Description</label>
                  <textarea id="meeting-description" v-model="meetingRow.Description" rows="3" class="form-control form-item"></textarea>
                </div>
                <div class="row">
                  <div class="col">
                    <label class="form-check-label pe-2" for="set-current-Meeting">Set as current meeting</label>
                    <input class="form-check-input" type="checkbox" v-model="meetingRow.SetAsCurrentMeeting" id="set-current-Meeting" checked>
                  </div>
                  <div class="col">
                    <label class="form-check-label pe-2" for="close-source-Meeting">Close source meeting</label>
                    <input class="form-check-input" type="checkbox" v-model="meetingRow.CloseCurrentMeeting" id="close-source-Meeting">
                  </div>
                  <div class="col">
                    <label class="form-check-label pe-2" for="copy-open-issues">Copy open issues</label>
                    <input class="form-check-input" type="checkbox" v-model="meetingRow.IncludeAgendaItems" id="copy-open-issues" checked>
                  </div>
                </div>
            </div>
            <div class="modal-footer">
                <div class="d-flex flex-row gap-2">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" class="btn btn-primary" @click="copyMeeting()">Create Meeting</button>
                </div>
            </div>
        </div>
    </div>
  </OModal>
</template>

<script setup>
import { ref, reactive, watch } from 'vue'
import OModal from 'o365.vue.components.Modal.vue';
import Procedure from 'o365.modules.Procedure.ts';
import { getOrCreateDataObject } from 'o365.vue.ts'

const props = defineProps({
  meetingRow: {
    type: Object,
    required: true
  }
});

const dsMeetingsCreated = getOrCreateDataObject({
    id: 'dsMeetingsCreated',
    viewName: 'atbv_Meet_Meetings',
    whereClause: "",
    loadRecents: false,
    distinctRows: true,
    fields:
        [
            {name: "ID", type: "number" },
            {name: "Name", type: "string" },
            {name: "Series_ID", type: "number" },
        ]
})

const OModalRef = ref(null);

/*
const meetingRow = computed(() => {return { 
  Name: "Meeting Name", 
  Goal: "", 
  StartDate: null, 
  EndDate: null, 
  Series_ID: props.seriesID, 
  CopyFromMeeting_ID: props.meetingID,
  shouldCopyOpenIssues: true, 
  shouldSetAsCurrentMeeting: true,
  shouldClosePreviousMeeting: false
}});
*/

const meetingRow = reactive({ 
  Name: "Meeting Name", 
  Description: "",
  Meeting_ID: props.meetingRow.ID,
  StartTime: null,
  DateTime: null,
  SetAsCurrentMeeting: true,
  IncludeAgendaItems: true,
  IncludeParticipants: true,
  IncludeCompletedActions: false,
  IncludeSharedWith: true,
  CloseCurrentMeeting: false,
  IncludeClosedAgendaItems: false,
});

watch(() => props.meetingRow.ID, (ID) => {
  meetingRow.Meeting_ID = ID;
});

const procCreateNewSeriesMeet = new Procedure({ id:"procCreateNewSeriesMeet", procedureName:"astp_Meet_MeetingCopy" });
const procCreateSeries = new Procedure({ id:"procCreateSeries", procedureName:"astp_Meet_MeetingCreateSeries" });

async function copyMeeting(){
  if(!props.meetingRow.Series_ID) {
    await procCreateSeries.execute({
      Meeting_ID: props.meetingRow.ID,
    })
  }
  const newMeet = await procCreateNewSeriesMeet.execute(meetingRow);
  const newMeetingID = newMeet.Table[0].NewMeeting_ID

  window.open(`/meeting-boards/meeting-board?MeetingID=${newMeetingID}`);
  OModalRef.value.hide();
  emit('created');
}

const emit = defineEmits(['created']);
defineExpose({OModalRef})

</script>

<style>
[data-bs-theme="dark"] {
  --form-item-background-color: #252a2e;
}
[data-bs-theme="light"] {
   --form-item-background-color: #f8f8f8;
}

.form-item {
  background-color: var(--form-item-background-color);
}

#Meeting-goal { 
  resize: none;
}
</style>